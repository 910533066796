/* Import Bootstrap */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Base styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Container for the entire app */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure header and footer span entire width */
header, footer {
  width: 100%;
}

/* Main content area with padding and background color */
main {
  flex: 1;
  padding: 20px;
  background: #f0f0f0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  main {
    padding: 15px;
  }

  .app {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  main {
    padding: 10px;
  }
}
