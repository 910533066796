/* Add this to your CSS file */
.table-responsive {
  overflow-x: auto;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .table-responsive table {
    width: 100%;
    overflow-x: auto;
  }

  .table thead th {
    font-size: 14px;
  }

  .table tbody td {
    font-size: 13px;
  }

  .pagination {
    flex-wrap: wrap;
  }

  .page-item .page-link {
    padding: 0.5rem 0.75rem;
  }

  .btn-info {
    font-size: 13px;
    padding: 5px 10px;
  }
}

.customLandingContainer {
  width: 92%;
  margin: auto;
}

