
.scrollable-div {
  max-height: 650px; 
  overflow-y: auto;
  padding-right: 15px;
  border: 1px solid rgb(220, 216, 216);
  margin-top: 20px;
  padding-top: 10px;
  padding-left: 5px;
}

.loader-center{
  display: flex;
  justify-content: center;
}